import BaseNotificationModel from 'Models/Notifications/BaseNotificationModel.interface';

import {
  BaseNotificationStyles,
  StyledContentContainer,
} from './BaseNotificationStyles';

function KexNotificationBanner({ message }: BaseNotificationModel) {
  return (
    <>
      {message ? (
        <BaseNotificationStyles>
          <StyledContentContainer>
            <div>{message}</div>
          </StyledContentContainer>
        </BaseNotificationStyles>
      ) : (
        ''
      )}
    </>
  );
}

export default KexNotificationBanner;
